<div class="{{navbarClass}}">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img style="height:40px" src="assets/img/Spera7.png" alt="logo">
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item"><a href="#" class="nav-link">Home</a></li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link">Pricing</a></li>

                    <li class="nav-item"><a href="#" class="nav-link">Services <i class='bx bx-chevron-down'></i></a>
                        <ul class="dropdown-menu">

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link">IT & Crypto Consulting</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a  class="nav-link">Digital Marketing</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a  class="nav-link">Web & Mobile Development</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link">Cloud Hosting Services</a></li>

                            <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link">UI / UX Design</a></li>
                        </ul>
                    </li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link">Clients</a></li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link">FAQ</a></li>
                    <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a class="nav-link">Contact Us</a></li>
                </ul>
            </div>
        </nav>
    </div>
</div>
