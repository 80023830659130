<div class="banner-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-5 col-md-12">
                <div class="banner-img banner-video">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="video-box">
                                <a href="https://www.youtube.com/watch?v=tdbAL2FNOc8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="banner-content">
                    <div class="content">
                        <div class="banner-content-slides owl-carousel owl-theme">
                            <div class="inner-content plr-15">
                                <h1>IT & Crypto Consulting</h1>
                                <br>
                                <a routerLink="/contact" class="default-btn"><i class="bx bxs-rocket"></i> Contact Us<span></span></a>
                            </div>
                            <div class="inner-content plr-15">
                                <h1>Digital Marketing</h1>
                                <br>
                                <a routerLink="/contact" class="default-btn"><i class="bx bxs-rocket"></i> Contact Us<span></span></a>
                            </div>
                            <div class="inner-content plr-15">
                                <h1>Web & Mobile Development</h1>
                                <br>
                                <a routerLink="/contact" class="default-btn"><i class="bx bxs-rocket"></i> Contact Us<span></span></a>
                            </div>
                            <div class="inner-content plr-15">
                                <h1>Cloud Hosting Services</h1>
                                <br>
                                <a routerLink="/contact" class="default-btn"><i class="bx bxs-rocket"></i> Contact Us<span></span></a>
                            </div>
                            <div class="inner-content plr-15">
                                <h1>UI / UX Design</h1>
                                <br>
                                <a routerLink="/contact" class="default-btn"><i class="bx bxs-rocket"></i> Contact Us<span></span></a>
                            </div>
                        </div>
                        <br>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="pricing-area pricing-area-desktop pt-100 pb-70 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <i class="fas fa-wallet"></i>
            <h2>General Pricing</h2>
        </div>
        <div class="tab pricing-list-tab">
            <div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="single-pricing-table left-align">
                            <div class="pricing-header" style="text-align: center;">
                                <h1>Consulting</h1>
                            </div>
                            <div class="pricing-header">
                                <h3>Digital Marketing</h3>
                            </div>
                            <div class="price">
                                <sup>$</sup>200 <sub>/ hourly</sub>
                            </div>
                            <div class="pricing-header">
                                <h3>General Technologies</h3>
                            </div>
                            <div class="price">
                                <sup>$</sup>250 <sub>/ hourly</sub>
                            </div>
                            <div class="pricing-header">
                                <h3>Crypto Technologies</h3>
                            </div>
                            <div class="price">
                                <sup>$</sup>500 <sub>/ hourly</sub>
                            </div>
                            <div class="pricing-header">
                                <h3>Technology Audit</h3>
                            </div>
                            <div class="price">
                                <sup>$</sup>500 <sub>/ hourly</sub>
                            </div>
                            <div class="pricing-header">
                                <h3>Advanced Technologies</h3>
                            </div>
                            <div class="price">
                                <sup>$</sup>750 <sub>/ hourly</sub>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="single-pricing-table left-align">
                            <div class="pricing-header" style="text-align: center;">
                                <h1>Project Creation</h1>
                            </div>
                            <div class="pricing-header">
                                <h3>General Website or App</h3>
                            </div>
                            <div class="price">
                                <sup>$</sup>1 to <sup>$</sup>50 <sub>THOUSAND</sub>
                            </div>
                            <div class="pricing-header">
                                <h3>Advanced Website or App</h3>
                            </div>
                            <div class="price">
                                <sup>$</sup>51 to <sup>$</sup>250 <sub>THOUSAND</sub>
                            </div>
                            <div class="pricing-header">
                                <h3>Blockchain Project</h3>
                            </div>
                            <div class="price">
                                <sup>$</sup>10 to <sup>$</sup>250 <sub>THOUSAND</sub>
                            </div>
                            <div class="pricing-header">
                                <h3>NFT Project</h3>
                            </div>
                            <div class="price">
                                <sup>$</sup>10 to <sup>$</sup>250 <sub>THOUSAND</sub>
                            </div>
                            <div class="pricing-header">
                                <h3>Advanced Technologies</h3>
                            </div>
                            <div class="price">
                               Let's Discuss
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-area pricing-area-mobile pt-100 pb-70 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h1>General Pricing</h1>
        </div>
        <div class="tab pricing-list-tab">
            <ul class="tabs">
                <li><a href="#"><i class="bx bx bxs-analyse"></i>Consulting</a></li>
                <li><a href="#"><i class="bx bx bxs-log-in-circle"></i>Project Creation</a></li>
            </ul>
            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>Digital Marketing</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>200 <sub>/ hourly</sub>
                                </div>
                                <div class="pricing-header">
                                    <h3>General Technologies</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>250 <sub>/ hourly</sub>
                                </div>
                                <div class="pricing-header">
                                    <h3>Crypto Technologies</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>500 <sub>/ hourly</sub>
                                </div>
                                <div class="pricing-header">
                                    <h3>Technology Audit</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>500 <sub>/ hourly</sub>
                                </div>
                                <div class="pricing-header">
                                    <h3>Advanced Technologies</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>750 <sub>/ hourly</sub>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-pricing-table left-align">
                                <div class="pricing-header">
                                    <h3>General Website or App</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>1 to <sup>$</sup>50 <sub>THOUSAND</sub>
                                </div>
                                <div class="pricing-header">
                                    <h3>Advanced Website or App</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>51 to <sup>$</sup>250 <sub>THOUSAND</sub>
                                </div>
                                <div class="pricing-header">
                                    <h3>Blockchain Project</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>10 to <sup>$</sup>250 <sub>THOUSAND</sub>
                                </div>
                                <div class="pricing-header">
                                    <h3>NFT Project</h3>
                                </div>
                                <div class="price">
                                    <sup>$</sup>10 to <sup>$</sup>250 <sub>THOUSAND</sub>
                                </div>
                                <div class="pricing-header">
                                    <h3>Advanced Technologies</h3>
                                </div>
                                <div class="price">
                                   Let's Discuss
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-right-shape ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="icon">
                        <img style="height:60px" src="assets/img/spera.svg" alt="image">
                    </div>
                    <h2>IT & Crypto Consulting</h2>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>
                                IT Architectural Design
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>
                                Planning & Management
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>
                                Crypto Consulting
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>
                                General IT Consulting
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>
                                NFT Collection
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>
                                Data Engineering
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>
                                Blockchain Consulting
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>
                                Crypto Tokenomics
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/service5.svg" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-left-color bg-left-shape bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/service4.svg" alt="image">
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="icon">
                        <img style="height:60px" src="assets/img/spera.svg" alt="image">
                    </div>
                    <h2>Digital Marketing</h2>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>  SEO Marketing
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Email Marketing
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Social Media Marketing
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Data Scraping
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Google Analytics
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Youtube Marketing
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Event Streaming
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Twitch
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-right-shape ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="icon">
                        <img style="height:60px" src="assets/img/spera.svg" alt="image">
                    </div>
                    <h2>Web & Mobile Development</h2>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>  Responsive Web Design
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Game Development
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Mobile App Development
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Solana Development
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Solidity Development
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Angular Web Development
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/service1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-left-color bg-left-shape bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/service2.svg" alt="image">
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <div class="icon">
                        <img style="height:60px" src="assets/img/spera.svg" alt="image">
                    </div>
                    <h2>Cloud Hosting Services</h2>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>  Cloud Databases
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Hybrid Cloud
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Email Servers
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Website Hosting
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> File Storage
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Backup Systems
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Azure Suites
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> AWS Suites
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-right-shape ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content it-service-content">
                <div class="content left-content">
                    <div class="icon">
                        <img style="height:60px" src="assets/img/spera.svg" alt="image">
                    </div>
                    <h2>UI / UX Design</h2>
                    <br>
                    <br>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i>  Mobile App Design
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Web Design
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> eCommerce Design
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Product Design
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> Game Design
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box">
                                <i class='bx bxs-badge-check'></i> NFT Collection
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="services-image">
                <div class="image">
                    <img src="assets/img/service3.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Trusted Clients</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo">
                <a href="https://teeboxcaddie.io">
                    <img style="height:150px" src="assets/img/teeboxcaddieFinal.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="https://thecryptowooks.io">
                    <img style="height:150px" src="assets/img/WookLogoClear.svg" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="https://h2o-transports.com">
                    <img style="height:150px" src="assets/img/H2O.svg"  alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="https://ariesautoservices.com">
                    <img style="height:150px" src="assets/img/Aries.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="https://verabooks.io">
                    <img style="height:150px" src="assets/img/VB-Teal.png"  alt="image">
                </a>
            </div>
            <div class="single-clients-logo">
                <a href="https://swish.game">
                    <img style="height:150px" src="assets/img/Swish-with-Icon.png"  alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="faq-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="faq-accordion">
                    <h2>Frequently Asked Question's</h2>
                    <div class="accordion" id="faqAccordion">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Who do I call if I notice an issue? <i class="bx bx-plus"></i>
                                </button>
                            </div>
                            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    Do not hesitate, call us immediately at our emergency hotline. 210-419-4674
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Why am I not seeing full utilization of my data? <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    Time, it takes time for our process to learn and get vaule out of data to fill the dashboards.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Does the price of my agreement ever go up? <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    The price rises depending on a various of items. These items depend on the SLA approved
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    How can I cancel/pause my subscription? <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    By calling our direct line and we can help you with that.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Can I pay online? <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    Yes, login to your account and pay bill from there.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="faq-image">
                    <img src="assets/img/faq-img.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <p>Drop us Message Below</p>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <h3>Some other great reasons to choose us</h3>
                        <p>Take the first steps towards increased productivity and reduced stress with Spera.</p>
                        <p>We are a highly trained, dedicated team, helping entrepreneurs, professionals, and small teams streamline success - not just in work, but in life too. We help spare your time to focus on the bigger picture by taking care of the little details.</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i> Staff works from our office in Texas</li>
                            <li><i class='bx bx-badge-check'></i> General Consultation</li>
                            <li><i class='bx bx-badge-check'></i> Crypto Consultation</li>
                            <li><i class='bx bx-badge-check'></i> Data Engineering</li>
                            <li><i class='bx bx-badge-check'></i> Custom Project Build</li>

                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>Contact Us</h3>
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Your Subject" #subject>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6" required placeholder="Your Message"></textarea>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <a class="default-btn" href="mailto:info@speratechnologies.io?subject=M{{subject.value}}"><i class='bx bxs-paper-plane'></i>Send Message<span></span></a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="contact-info">
                <div class="contact-info-content">
                    <ul class="social">
                        <li><a href="https://www.linkedin.com/company/spera-technologies" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
