<footer class="footer-area">
    <div class="divider"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img style="height:80px" src="assets/img/SperaWhite.svg" alt="image"></a>
                    </div>
                    <br>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Company</h3>
                    <ul class="services-list">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/">Pricing</a></li>
                        <li><a routerLink="/">Services</a></li>
                        <li><a routerLink="/">Trusted Clients</a></li>
                        <li><a routerLink="/">FAQ</a></li>
                        <li><a routerLink="/">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>
                    <ul class="support-list">
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>Location: <a href="https://goo.gl/maps/MQ78iGP5g9VgZcJ38" target="_blank">Texas</a></li>
                        <li>Email: <a href="mailto:info@speratechnologies.io">info@speratechnologies.io</a></li>
                        <li>Phone: <a href="tel:+2104194674">(+1) 210-419-4674</a></li>
                    </ul>
                    <ul class="social">
                        <li><a href="https://www.linkedin.com/company/spera-technologies" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>Copyright @2022 Spera Technologies</p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>